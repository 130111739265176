import React from "react";
import '../BigDaddyCSS.css';
import { CRow } from '@coreui/react';
import * as fcl from "@onflow/fcl";

function BigDaddyMinterLoginPage() {

  const bigdaddyhandleLogIn = () => {
    fcl.logIn();
  };

  return (
    <>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
      <CRow className="mt-5 mb-5" xs={{ gutterX: 5 }}>  {/* Increased margin-top (mt-5) */}
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
          <button className="bigdaddy-button" onClick={bigdaddyhandleLogIn}>Log In</button>
        </div>
      </CRow>
    </div>
  </>
  );
}

export default BigDaddyMinterLoginPage;