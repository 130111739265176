import React from "react";
import '../BigDaddyCSS.css';
import { useBigDaddyMinterContext } from '../Provider/BigDaddyMinterContext';


function BigDaddyMinterActivateAccountPage() {
  const {handleActivateBigDaddyCollection} = useBigDaddyMinterContext();

  return (
<>
<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
<h1 style={{ margin: '50px', color: 'white' }}>Activate your BigDaddy Collection</h1>

</div>
<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
<h2 style={{ color: 'white' }}>In order to Mint your NFTs you have to create a collection in your blockchain Account.</h2>
</div>
<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
  <h2 style={{ color: 'white' }}>If it is not allready done, this will also create you a FLOW and USDC Account in order to receive payments.</h2>
</div>
<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }} >
  <button className="bigdaddy-button" onClick={handleActivateBigDaddyCollection} style={{ margin: '50px'}}>Activate your Collection</button>
</div>
</>
  );
}

export default BigDaddyMinterActivateAccountPage;
